// Config values
$platform-name: "ballyhouradevelopment";
$assets-path: "../assets/";


// Colours preset
$_green-dark: #1F6234;
$_green-light: #7CAD58;
$_green-light-accessible: #72a14f;
$_yellow-light: #f3e48b;
$_orange: #ce9f3c;

// Colours
$brand-primary: $_green-dark;
$brand-primary-text-contrast-override: #FFF;
$brand-secondary: $_green-light-accessible;
$brand-secondary-text-contrast-override: #FFF;

$donate-colour: $brand-primary;

// Create greyscale
$black: #000;
$grey-dark: #16161D;
$grey: #464A4C;
$grey-light: #636C72;
$grey-lighter: #ECEEEF;
$grey-lighter-better-contrast-annual-report: #d8d9da;
$grey-lightest: #F0F0F0;
$white: #FFF;

// Layout
$container-max-width: 1170px;
$gap-width: 30px;
$site-bleed: 20px;

$border-radius: 3px;
$border-colour: #CCC;
$box-shadow: none;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1048px,
  xl: 1240px
);


// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;


// Misc
$body-bg: $white;
$body-colour: $grey-dark;
$profile-pics-enabled: true; // Turn off donor, fundraiser, blog author photos sitewide
$border-width: 1px;
$date-ordinals-enabled: true; // Turn off 'st', 'nd', 'rd' from dates


// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;


// Logo
$logo: "logo.png";
$logo-width: 225px;
$logo-height: 80px;
$logo-absolute: false; // true if we want the logo to vertically intersect with the menu
$logo-breakpoint: map-get($breakpoints, md);
$logo-mobile: $logo;
$logo-mobile-retina: $logo-mobile;
$logo-mobile-width: $logo-width * 0.7;
$logo-mobile-height: $logo-height * 0.7;


// Typography
$font-family-base: 'Source Sans Pro', sans-serif;
$font-size-base: 1.05rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.25em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em;
$headings-margin-bottom: 0.5em;
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-decoration-colour: initial;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome"; // TODO: Add in alternatives here


// Buttons
$btn-background-colour: $brand-secondary;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 2em;
$btn-padding-y: 1em;
$btn-font-family: $font-family-base;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-text-transform: none;
$btn-text-decoration: none;
$btn-line-height: 1.25;
$btn-letter-spacing: inherit;
$btn-hover-style: darken; // darken or lighten the button on hover
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-colour);


// Social icons
$social-icons-background-colour: transparent;
$social-icons-colour: $body-colour;
$social-icons-hover-colour: $brand-primary;
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.3rem;
$social-icons-gap: 7.5px;
$social-icons-border-radius: $btn-border-radius;
$social-icons-box-shadow: $btn-box-shadow;

// Header specific
$social-icons-header-background-colour: transparent;
$social-icons-header-colour: $body-colour;
$social-icons-header-hover-colour: $brand-primary;

// Footer specific social icons
$social-icons-footer-background-colour: $social-icons-background-colour; // A colour, transparent or 'brand'
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $white; // $brand-secondary;


// Share this page
$share-enabled: true;
$share-text-align: left; // left, center, right
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;


// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;


// Tables
$table-cell-padding: 0.75rem;


// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: 0px;
$card-box-shadow: none;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: transparent;
$card-image-padding: 0;
$card-image-border-radius: $border-radius;
$card-details-padding: $spacer;
$card-details-background-colour: $white;
$card-details-background-gradient: none; // none or CSS gradient
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $link-colour;
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: $spacer;
$card-summary-enabled: true;
$card-summary-colour: text-contrast($card-details-background-colour);
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: $white;
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: $body-colour;
$card-footer-padding: 0.5rem $card-details-padding;
$card-min-width: 250px;

// Card basic
$card-basic-image-position: above; // above, below

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom;
$card-text-overlay-details-position-x: left;
$card-text-overlay-breakpoint: map-get($breakpoints, lg);

// Card text over
$card-text-over-image-vertical-text-position: center;
$card-text-over-image-darken-image-amount: 0;
$card-text-over-image-tint-colour: transparent;
$card-text-over-image-show-summary-on-hover: false;

// Card side by side
$card-side-by-side-image-position: right;
$card-side-by-side-image-width: 50%;
$card-side-by-side-grow-image: true;
$card-side-by-side-vertical-text-position: center;
// $card-side-by-side-breakpoint: calc(#{$container-max-width} + (50px * 2));
$card-side-by-side-breakpoint: map-get($breakpoints, lg); // used on annual report subsite

// Card hover state
$card-hover-border-colour: $border-colour;
$card-hover-box-shadow: $card-box-shadow;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.1;
$card-hover-details-background-colour: $card-details-background-colour;
$card-hover-details-background-gradient: $card-details-background-gradient; // none or CSS gradient
$card-hover-heading-colour: $card-heading-colour;
$card-hover-summary-colour: text-contrast($card-hover-details-background-colour);

// Event cards
$event-card-floating-date: false;
// $event-card-floating-date-background-colour: $card-details-background-colour;
// $event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
// $event-card-floating-date-border-radius: $border-radius;
// $event-card-floating-date-margin-y: $spacer;
// $event-card-floating-date-margin-x: $spacer;


// Global alert
$global-alert-background-colour: $brand-primary;
$global-alert-colour: text-contrast($global-alert-background-colour);


// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100%; // $container-max-width
// $menu-admin-background-colour: $grey-lighter;
// $menu-admin-background-gradient: none; // none or CSS gradient
// $menu-admin-contents-max-width: $container-max-width; // 100%
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: right; // left, center, right
// $menu-admin-link-colour: $link-colour;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: true;
// $menu-admin-manager-link-enabled: true;
// $menu-admin-my-details-link-enabled: true;
// $menu-admin-logout-link-enabled: true;
// $menu-admin-to-nav-breakpoint: 0px;


// Header
$header-background-colour: $white;
$header-background-gradient: none;
$header-box-shadow: $box-shadow;

$header-absolute: false;
// $header-absolute-background: linear-gradient(to bottom, rgba($black, 0.5), rgba($black, 0)); // Colour or gradient
// $header-absolute-breakpoint: map-get($breakpoints, sm); // When do you want to abandon the absolute header and return to solid?

// Header content
$header-content-max-width: $container-max-width; // 100%
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: 0;
$header-content-padding-bottom: 0;
$header-content-padding-left: #{$site-bleed * 0.5};
$header-content-padding-right: #{$site-bleed * 0.5};

// Tagline
$tagline-enabled: false;
// $tagline-font-family: $headings-font-family;
// $tagline-font-weight: $font-weight-bold;
// $tagline-font-size: $font-size-h5;
// $tagline-colour: $headings-colour;
// $tagline-above-cta-breakpoint: 0;
// $tagline-breakpoint: map-get($breakpoints, md);

// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 0;
// $cta-padding-right-at-nav-breakpoint: 6.5em; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint
$cta-btn-breakpoint: map-get($breakpoints, sm);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;
$header-search-margin-right: $social-icons-gap;
$header-search-breakpoint: map-get($breakpoints, sm);

// Search - input
$header-search-input-max-width: 200px;
$header-search-input-height: $social-icons-height;
$header-search-input-margin-right: -$social-icons-width;
$header-search-input-padding: $input-padding-y $input-padding-x;
$header-search-input-placeholder-colour: rgba($body-colour, 0.75);
$header-search-input-border-radius: 0;
$header-search-input-border-colour: $brand-primary;
$header-search-input-border-width: 0px 0px 1px 0px;
$header-search-input-background-colour: $white;
$header-search-input-colour: $body-colour;
$header-search-input-focus-background-colour: $grey-lightest;
$header-search-input-focus-colour: $body-colour;

// Search - button
$header-search-button-width: $social-icons-width;
$header-search-button-height: $social-icons-height;
$header-search-button-background-colour: transparent;
$header-search-button-icon-font-size: $social-icons-font-size;
$header-search-button-icon-colour: $brand-primary;
$header-search-button-border-radius: $border-radius;

// Search when in nav
$nav-search-enabled: $header-search-enabled;
$nav-search-input-margin-right: 0.01px;
$nav-search-input-padding: $input-padding-y $input-padding-x;
$nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
$nav-search-input-border-colour: $header-search-input-border-colour;
$nav-search-input-border-width: 0 0 2px 0;
$nav-search-input-background-colour: $white;
$nav-search-button-background-colour: $white;
$nav-search-button-icon-colour: $brand-primary;

// Social icons -- Colours set above (search for '$social-icons-header')
$header-social-icons-margin-right: 10px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, sm);

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $white;
$header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
$header-sticky-logo: $logo;
$header-sticky-logo-width: $logo-mobile-width;
$header-sticky-logo-height: $logo-mobile-height;
$header-sticky-menu-admin-enabled: false;
$header-sticky-search-enabled: false;
$header-sticky-social-icons-enabled: false;
$header-sticky-cta-btns-enabled: false;
$header-sticky-header-content-margin-top: $header-content-margin-top * 0.5;
$header-sticky-header-content-margin-bottom: $header-content-margin-bottom * 0.5;
$header-sticky-nav-margin-bottom: #{$spacer * 0.5};


// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: transparent;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 1rem;
$nav-top-level-item-colour: $body-colour;
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $body-colour;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: false;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;
// $nav-top-level-first-item-home-icon: false;
// $nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $white;
$nav-submenu-box-shadow: none;
$nav-submenu-border-radius: $border-radius;
$nav-submenu-item-padding: 10px 20px;
$nav-submenu-item-colour: $body-colour;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-bold;
$nav-submenu-item-hover-background-colour: $brand-primary;
$nav-submenu-item-hover-colour: text-contrast($nav-submenu-item-hover-background-colour);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $nav-submenu-item-colour;

// Burger button
$burger-btn-background-colour: $brand-primary;
$burger-btn-text-colour: text-contrast($burger-btn-background-colour);
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: null;

// Nav normal
$nav-normal-max-width: $container-max-width; // $container-max-width
$nav-normal-margin-top: #{$spacer * -4};
$nav-normal-margin-bottom: #{$spacer * 0.5};
$nav-normal-contents-max-width: $container-max-width;
$nav-normal-align-items: right;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $white;
$nav-normal-mobile-top-level-item-colour: $body-colour;
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.08);
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: $brand-primary;

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: false;
// $nav-parent-clickable-chevron-background-colour: rgba(#fff, 0.75);
// $nav-parent-clickable-chevron-colour: $nav-normal-mobile-background-colour;
// $nav-parent-clickable-chevron-icon-type: "chevron"; // can take "chevron" or "plus"
// $nav-parent-clickable-chevron-active-background-colour: #fff;
// $nav-parent-clickable-chevron-active-colour: $nav-parent-clickable-chevron-colour;


// Specifically when no banner present and you have gone for $header-absolute: true
// $no-banner-header-background-colour: $header-background-colour;
// $no-banner-logo: $logo;
// $no-banner-logo-retina: $no-banner-logo;
// $no-banner-tagline-colour: $tagline-colour;
// $no-banner-social-icons-header-background-colour: $social-icons-header-background-colour; // A colour, transparent, or 'brand'
// $no-banner-social-icons-header-colour: $social-icons-header-colour; // A colour, or 'brand'
// $no-banner-social-icons-header-hover-colour: $social-icons-header-hover-colour; // A colour, or 'brand'
// $no-banner-nav-background-colour: $nav-background-colour;
// $no-banner-nav-top-level-item-colour: $nav-top-level-item-colour;
// $no-banner-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $no-banner-header-search-input-placeholder-colour: $header-search-input-placeholder-colour;
// $no-banner-header-search-input-border-colour: $header-search-input-border-colour;
// $no-banner-header-search-input-background-colour: $header-search-input-background-colour;
// $no-banner-header-search-button-background-colour: $header-search-button-background-colour;
// $no-banner-header-search-button-icon-colour: $header-search-button-icon-colour;


// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: rgba($brand-primary, 0.8);
$carousel-contents-max-width: 100%;
$carousel-breakpoint: map-get($breakpoints, lg);
$carousel-details-style: overlay;
$carousel-details-position-y: center;
$carousel-details-position-x: left;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 40%;
$carousel-details-min-height: 100%;
$carousel-details-padding: $spacer * 1.5;
$carousel-details-margin-y: 0px;
$carousel-details-margin-x: $spacer * 5;
$carousel-details-border-radius: 0;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: text-contrast($brand-primary);
$carousel-heading-font-size: $font-size-h1;
$carousel-summary-enabled: false;
// $carousel-summary-colour: text-contrast($carousel-details-background-colour);
// $carousel-summary-font-size: inherit;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: transparent;
$carousel-read-more-border-colour: text-contrast($brand-primary);
$carousel-read-more-colour: text-contrast($brand-primary);
$carousel-read-more-font-size: $font-size-base;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;
// $carousel-controls-button-size: 40px;
// $carousel-controls-button-gap: 0px;
// $carousel-controls-button-background-colour: $btn-background-colour;
// $carousel-controls-button-border-radius: $border-radius;
// $carousel-controls-button-box-shadow: $box-shadow;
// $carousel-controls-position-y: bottom; // top, center, bottom
// $carousel-controls-position-x: right; // split, left or right
// $carousel-controls-margin-y: 0px;
// $carousel-controls-margin-x: 0px;
// $carousel-controls-icon-colour: $btn-text-colour;
// $carousel-controls-icon-font-size: 1em;
// $carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
// $carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: true;
$carousel-buttons-type: dots; // dots or tabs

// Carousel buttons dots
$carousel-dots-position-y: bottom;
$carousel-dots-position-x: center;
$carousel-dots-padding: #{$spacer * 1.5};
$carousel-dots-colour: $white;
$carousel-dots-opacity: 0.5;
$carousel-dots-active-colour: $brand-secondary;

// Carousel buttons tabs
// $carousel-tabs-font-size: $font-size-h3;
// $carousel-tabs-background-colour: $grey-lighter;
// $carousel-tabs-colour: $brand-primary;
// $carousel-tabs-active-background-colour: $brand-primary;
// $carousel-tabs-active-colour: text-contrast($carousel-tabs-active-background-colour);
// $carousel-tabs-active-triangle-enabled: true;
// $carousel-tabs-text-align: left;
// $carousel-tabs-font-weight: $btn-font-weight;
// $carousel-tabs-text-transform: $btn-text-transform;
// $carousel-tabs-padding-x: $spacer;
// $carousel-tabs-padding-y: $spacer;
// $carousel-tabs-separator-width: 0px; // Width of border between tabs
// $carousel-tabs-separator-colour: $border-colour;

// Carousel video play button (before breakpoint, when carousel details present)
$carousel-video-btn-position-y: 50%;
$carousel-video-btn-position-x: 66%;

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
// $carousel-scroll-btn-size: $carousel-controls-button-size;
// $carousel-scroll-btn-background-colour: $btn-background-colour;
// $carousel-scroll-btn-border-radius: $border-radius;
// $carousel-scroll-btn-box-shadow: $box-shadow;
// $carousel-scroll-btn-icon-colour: $btn-text-colour;
// $carousel-scroll-btn-icon-font-size: 1em;
// // $carousel-scroll-btn-icon: '\f078'; // TODO, similar issue to $carousel-controls-next-icon
// $carousel-scroll-btn-margin-y: 15%; // Can use a negative value to get it to peek over the edge
// $carousel-scroll-btn-animation-prefab: 0; // Pick a number from 0 (off) to 3!

// Carousel background YouTube video
$carousel-yt-background-enabled: false;
$carousel-yt-background-size: "cover";
$carousel-yt-cover-padding-bottom: 56.25%;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $brand-primary;
$carousel-mobile-heading-colour: text-contrast($carousel-mobile-details-background-colour);
$carousel-mobile-summary-colour: text-contrast($carousel-mobile-details-background-colour);

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 220px;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: transparent;
$home-intro-background-gradient: none;
$home-intro-background-image: null;
$home-intro-background-image-opacity: 0.5;
$home-intro-box-shadow: none;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 4;
$home-intro-margin-top: 15px;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: $headings-text-transform;
$home-intro-font-family: $headings-font-family; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-h1;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: $line-height-base;
$home-intro-colour: $body-colour;
$home-intro-border-radius: 0;
$home-intro-mobile-padding-y: $home-intro-padding-y * .7;
$home-intro-mobile-font-size: $home-intro-font-size * .85;


// Home headlines intro
$home-headlines-intro-enabled: false;
// $home-headlines-intro-snippet-title-enabled: false;
// $home-headlines-intro-max-width: 100%;
// $home-headlines-intro-content-max-width: $container-max-width;
// $home-headlines-intro-background-colour: $white;
// $home-headlines-intro-padding-top: $spacer * 5;
// $home-headlines-intro-padding-bottom: 0;
// $home-headlines-intro-margin-top: 0;
// $home-headlines-intro-margin-bottom: 0;
// $home-headlines-intro-text-align: center;
// $home-headlines-intro-text-transform: $headings-text-transform;
// $home-headlines-intro-font-family: $headings-font-family;
// $home-headlines-intro-font-size: $font-size-h2;
// $home-headlines-intro-colour: text-contrast($home-headlines-intro-background-colour);


// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: $white;
$home-features-padding-top: 0;
$home-features-padding-bottom: $spacer * 4;
$home-features-cards-entry-animation-prefab: 0; // See https://github.com/RaisingITDev/fresco/wiki/entry-animations
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;


// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 4;
$impact-stats-background-colour: $white;
$impact-stats-background-gradient: none; // none or CSS gradient
$impact-stats-background-image: null;
$impact-stats-background-image-opacity: 0.5;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: $border-radius;
$impact-stats-colour: text-contrast($impact-stats-background-colour);
$impact-stats-text-align: center; // left, center, right
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, md); // When do you want to break the overall layout from row to column?

$impact-stats-overall-container-direction: column; // column, row for the .homeImpactWrapper
$impact-stats-direction: row; // Do you want the stats in a row or column?
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true; //true or false
$impact-stats-heading-font-size: $font-size-h2;
$impact-stats-heading-text-align: $impact-stats-text-align; // left, center, right
$impact-stats-heading-colour: inherit;
$impact-stats-heading-margin-bottom: $spacer * 2;
$impact-stats-heading-text-transform: $headings-text-transform;

// Individual stat
$impact-stat-min-width: 180px;
$impact-stat-direction: column; // column, row
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour; // Style border here
$impact-stat-border-width: 0 0 0 0; // Set border widths (t-r-b-l) here

// Figure
$impact-stats-figure-colour: $impact-stats-colour;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: $font-size-h1;

// Summary
$impact-stats-summary-colour: $body-colour;
$impact-stats-summary-font-size: 1.1em;
$impact-stats-summary-font-weight: $font-weight-bold;
$impact-stats-summary-margin-top: $spacer;


// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $grey-lightest;
$home-feeds-padding-top: $spacer * 4;
$home-feeds-padding-bottom: $spacer * 4;
$home-feeds-cards-entry-animation-prefab: 1;
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 1;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h2;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left; // left, center, right
$feeds-title-margin-bottom: $spacer;
$feeds-title-text-transform: $headings-text-transform;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;

// Twitter feed
$home-feed-twitter-enabled: false;
// $home-feed-twitter-card-gap-width: $card-gap-width;
// $home-feed-twitter-card-border: $card-border;
// $home-feed-twitter-card-border-radius: $card-border-radius;
// $home-feed-twitter-card-box-shadow: $card-box-shadow;
// $home-feed-twitter-card-text-align: $card-text-align;
// $home-feed-twitter-card-padding: $card-details-padding;
// $home-feed-twitter-card-background-colour: $card-details-background-colour;
// $home-feed-twitter-card-colour: text-contrast($home-feed-twitter-card-background-colour);
// $home-feed-twitter-card-min-width: $card-min-width;


// Footer
$footer-boxes-count: 3;
$footer-prefab: 4;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 2;
$footer-background-colour: $white;
$footer-background-gradient: none;
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: $brand-primary;
$footer-link-decoration: none;
$footer-link-hover-colour: darken($footer-link-colour, 10%);
$footer-link-hover-decoration: underline;
$footer-text-align: left;
$footer-font-size: $font-size-base;
$footer-headings-font-size: inherit;
$footer-headings-text-transform: $headings-text-transform;

// Footer admin links
$footer-admin-basket-link-enabled: true;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: true;
$footer-admin-my-details-link-enabled: true;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $brand-primary;
$newsletter-background-gradient: none; // none or CSS gradient
$newsletter-colour: text-contrast($newsletter-background-colour);
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h3;
$newsletter-heading-text-align: center; // left, center, right
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 3;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 50px;
$newsletter-button-background-colour: $white;
$newsletter-button-colour: $brand-primary;
$newsletter-button-border-colour: $white;
$newsletter-use-placeholders: false;
$newsletter-required-label-enabled: false;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 4;
$newsletter-hero-contents-max-width: $footer-contents-max-width;


// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: false;
$donors-list-profile-pics-enabled: false;


// Posts
$post-content-max-width: $container-max-width;
$post-body-background-colour: $white;


// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 2;
$header-text-margin-bottom: $spacer * 3;


// Page title
$page-title-font-size: $font-size-h2;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $brand-primary;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left;
$page-title-margin-top: $spacer * 2;
$page-title-margin-bottom: $headings-margin-bottom;
$page-title-md-font-size: $page-title-font-size * 0.8;
$page-title-sm-font-size: $page-title-font-size * 0.6;

// Page title over banner
$page-title-over-banner-enabled: false;
$page-title-over-banner-breakpoint: map-get($breakpoints, md);
$page-title-over-banner-background-colour: $brand-primary; // Add a background colour so that title is always legible as it appears over a photo
$page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour);
$page-title-over-banner-breadcrumb-enabled: true; // Toggle breadcrumbs - this CAN work but also some people might think looks a bit weird

// Sidebar
$sidebar-enabled: true;
$sidebar-layout: adjacent;
$sidebar-mobile-layout: below; // above or below - where does the sidebar go on mobile?
$sidebar-adjacent-width: 320px;
$sidebar-adjacent-gap: $spacer * 4;
$sidebar-background-colour: transparent;
$sidebar-padding: 0;
$sidebar-border-radius: $border-radius;

// Associated lists
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: true;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 3;
$associated-list-heading-font-size: $font-size-base;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-heading-colour: $link-colour;
$associated-item-headings-text-transform: $headings-text-transform;
$associated-item-summary-enabled: true;
$associated-item-border: true;

// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-body-background-colour: transparent;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width;

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: false;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: false;
$listed-appeal-totaliser-enabled: true;

// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: false;
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer * 2;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align; // left, center or right

// Blockquote
$blockquote-max-width: $post-content-max-width; // Either a px value or 100%
$blockquote-background-colour: $brand-primary;
$blockquote-background-gradient: none; // none or CSS gradient
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: $spacer * 2;
$blockquote-padding: $spacer * 2;
$blockquote-text-align: center; // left, center, right
$blockquote-font-size: 1.5em; // Em unit will cascade to children
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: text-contrast($blockquote-background-colour);
$blockquote-box-shadow: $box-shadow;


// Fundraising
$fundraising-enabled: true; // Quickly turn off fundraising features

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: $profile-pics-enabled;


// Donation form
$donation-form-layout: columns; // rows or columns
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: false; // Have you added the DonationTarrifsAndTabs.js custom layout?

// Donation amounts
$donation-amount-min-width: $card-min-width; // IF using columns layout, set a min-width value on the amounts to dictate how many columns you want. Default will be 3 (items grow to fill row)
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;


// Quick giving panel
$quick-giving-type: simple;
$quick-giving-background-colour: $donate-colour;
$quick-giving-background-gradient: none;
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-figure-colour: text-contrast($quick-giving-donation-amount-background-colour);
$quick-giving-donation-amount-border-width: $btn-border-width;
$quick-giving-donation-amount-border-colour: $donate-colour;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: text-contrast($quick-giving-donation-amount-selected-background-colour);


// Home quick giving panel
$home-quick-giving-enabled: false;
// $home-quick-giving-type: simple; // simple, adjacent or overlay
// $home-quick-giving-max-width: 100%;
// $home-quick-giving-content-max-width: 100%;
// $home-quick-giving-margin-top: 0;
// $home-quick-giving-margin-bottom: 0;
// $home-quick-giving-padding-y: $spacer * 5;
// $home-quick-giving-background-colour: $quick-giving-background-colour;
// $home-quick-giving-background-gradient: $quick-giving-background-gradient; // none or CSS gradient
// $home-quick-giving-background-image: null;
// $home-quick-giving-background-image-opacity: 0.5;
// $home-quick-giving-donation-amount-background-colour: $donate-colour;
// $home-quick-giving-donation-amount-figure-colour: text-contrast($home-quick-giving-donation-amount-background-colour);
// $home-quick-giving-donation-amount-border-width: $btn-border-width; // Only comes into play with transparent background
// $home-quick-giving-donation-amount-border-colour: $donate-colour;
// $home-quick-giving-donation-amount-selected-background-colour: $white;
// $home-quick-giving-donation-amount-selected-figure-colour: text-contrast($home-quick-giving-donation-amount-selected-background-colour);
// $home-quick-giving-donate-btn-background-colour: $btn-background-colour;
// $home-quick-giving-donate-btn-colour: $btn-text-colour;
// $home-quick-giving-donate-btn-border-colour: $btn-border-colour;
// $home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
// $home-quick-giving-colour: $body-colour; // This should cover any headings and text you have in the snippet
// $home-quick-giving-heading-colour: $headings-colour;
// $home-quick-giving-heading-font-size: $font-size-h2;

// // Adjacent homepage quick giving
// $home-quick-giving-type-adjacent-image-side: left; // If type is adjacent, which side do you want the image

// // Overlay homepage quick giving
// $home-quick-giving-type-overlay-items-side: left; // If type is overlay, which side do you want the copy and donation items?
// $home-quick-giving-type-overlay-gradient: linear-gradient(to left, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.75) 100%);


// Button actions
$button-actions-padding: 0;
$button-actions-background-colour: transparent;
$button-actions-align-buttons: left;


// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal;
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;


// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: false;


// Blog details
$blog-details-enabled: false;
// $blog-details-background-colour: $context-container-background-colour;
// $blog-details-padding: $context-container-padding;
// $blog-details-border-radius: $context-container-border-radius;
// $blog-details-profile-pic-enabled: false;


// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $context-container-background-colour;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $context-container-border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: $brand-primary;
$faq-item-btn-chevron-colour: text-contrast($faq-item-btn-background-colour);
$faq-item-hover-btn-background-colour: darken($faq-item-btn-background-colour, 10%);
$faq-item-hover-btn-chevron-colour: text-contrast($faq-item-hover-btn-background-colour);

// In Memory
$listing-inmem-masonry-layout: true;

// Shop
$shop-enabled: false;
// $basket-link-only-on-shop-pages: false;

// // Departments list
// $department-list-width: $sidebar-adjacent-width;
// $department-list-background-colour: $context-container-background-colour;
// $department-list-padding: $context-container-padding;
// $department-list-border-radius: $context-container-border-radius;

// // Product-price
// $product-price-colour: $brand-secondary;
// $product-price-font-size: $font-size-h4;

// // Product post
// $product-post-content-max-width: $post-content-max-width;

// // Product rating
// $product-rating-enabled: true;

// Subsite
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: false;
$subsite-hide-header-search: false;
$subsite-hide-main-cta: false;
$subsite-content-max-width: $container-max-width;
$subsite-title-font-size: $page-title-font-size;

// Subsite logo
$subsite-logo: 'title';
$subsite-logo-width: auto;
$subsite-logo-height: auto;
$subsite-logo-margin: $header-content-margin-top 0 0;

// Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint;
// $subsite-nav-max-width: $nav-normal-max-width;
// $subsite-nav-margin-top: $spacer;
// $subsite-nav-margin-bottom: $spacer;
// $subsite-nav-background-colour: $nav-background-colour;
// $subsite-nav-contents-max-width: $container-max-width;
// $subsite-nav-align-items: $nav-normal-align-items; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour;

// $subsite-nav-submenu-width: $nav-submenu-width;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour;


// Cookie consent
$cookie-consent-position: bottom; // Bottom or top
$cookie-consent-background-colour: $body-colour;
$cookie-consent-colour: text-contrast($cookie-consent-background-colour);
$cookie-consent-font-family: initial;
$cookie-consent-btn-background-colour: $brand-primary;
$cookie-consent-btn-text-colour: text-contrast($cookie-consent-btn-background-colour);


// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: true;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: true;


// Slick
$slick-enabled: true;