// Header mobile + sticky - Layout
@media (max-width: $nav-breakpoint) {
  .pageHeader .headerContent,
  .pageHeader.sticky .headerContent {
    margin-top: #{$spacer * 0.5};
    margin-bottom: #{$spacer * 0.5};
  }
}

// Search bar - Layout
// @media (min-width: #{$header-search-breakpoint + 1px}) {
//   .headerContent .header-search .searchContainer:not(.open) {
//     #siteSearch {
//       max-width: $header-search-button-width;
//       font-size: 0;
//       border: none;
//     }
//     button { pointer-events: none; }
//   }
// }


@media (min-width: #{$nav-breakpoint + 1px}) {
  // Header desktop - Layout
  .pageHeader .headerContent {
    align-items: flex-start; 
  }
  // Social icons - Layout
  .headerContent .mainCallToAction .socialIcons {
    margin-right: 0;
  }
  // Logo - Layout
  .mainLogo {
    margin-top: #{$spacer * 1.5};
    margin-bottom: #{$spacer * 1.5};
  }
  // Nav - Layout
  .menuMain {
    width: 100%;
    max-width: $nav-normal-max-width;
    padding-left: $header-content-padding-left;
    padding-right: $header-content-padding-right;
  }
  // Sticky header nav - Layout
  .pageHeader.sticky .menuMain {
    margin-top: #{$spacer * -3.75};
    ul.topLevel { width: 100%; }
  }

  .pageHeader.sticky .mainLogo {
    margin-top: #{$spacer * 0.5};
    margin-bottom: #{$spacer * 0.5};
  }
}


// Burger button - Format
.menuMainAlt {
  padding: 0.5rem 1rem;
  &.active,  &:hover {
    background-color: darken($burger-btn-background-colour, 10%);
    color: $burger-btn-text-colour;
  }
}


// Nav donate - Format
.menuMain ul.topLevel > li > a[aria-label='Donate'] {
  @include button(
    $btn-background-colour: $donate-colour,
    $btn-text-colour: text-contrast($donate-colour),
    $btn-padding-x: 1rem,
    $btn-padding-y: 0.5rem,
  );  
  margin-left: #{$spacer * 0.5};
  margin-right: 0;
  @media (max-width: $nav-breakpoint) {
    margin-left: #{$site-bleed * 0.5};
    justify-content: flex-start;
    padding: #{$spacer * 0.75};
    max-width: calc(100% - #{$site-bleed});
    margin-bottom: #{$spacer * 0.5};
  }
}


// Nav items hover - Format
.menuMain ul.topLevel a { 
  line-height: 1;
  &:hover { box-shadow: inset 0px -3px 0px 0px $brand-primary; }
}


// Carousel read more - Format
.carouselSlideReadMore {
  padding: 0;
  font-weight: $font-weight-base;
  &:hover {
    background-color: $carousel-read-more-background-colour;
    color: $carousel-read-more-colour;
  }
}


// Headings underline left - Format
.carouselSlideHeading,
.homefeaturecategory-homeboxfullwidth [class*='etails'][class*='rapper'] h2,
.feedsTitle {
  @include heading-underline(
    $colour: $brand-secondary,
    $width: 50px,
    $height: 5px,
    $margin-top: $spacer,
  );
}
  
// Headings underline center - Format
.homeIntro > *:last-of-type,
.homefeaturecategory-homeboximpactstats td:last-child > *:last-of-type {
  @include heading-underline(
    $colour: $brand-secondary,
    $width: 50px,
    $height: 5px,
    $justify: center,
    $margin-top: $spacer,
  );
}


// Carousel background - Format
@media (min-width: #{$carousel-breakpoint + 1px}) {
  .carouselSlideTitle:after {
    clip-path: polygon(0% 0%, calc(#{$carousel-details-max-width} + 10%) 0, calc(#{$carousel-details-max-width} + 5%) 100%, 0% 100%);
  }
}


@media (min-width: #{$card-text-overlay-breakpoint + 1px}) {
  // Homefeatures details - Layout
  .homeFeatures .homeFeature [class*="DetailsWrapper"] {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  } 



  // Homefeature full width - Layout
  .homeFeature:not(.homefeaturecategory-homeboximpactstats):not(.homefeaturecategory-homeboxquickgiving).homefeaturecategory-homeboxfullwidth {
    [class*="image-link"] {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $carousel-image-overlay;
        clip-path: polygon(calc(100% - calc(#{$carousel-details-max-width} + 10%)) 0%, 100% 0, 100% 100%, calc(100% - calc(#{$carousel-details-max-width} + 5%)) 100%);
      }
    }
    [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: auto;
      right: $carousel-details-margin-x;
      transform: none;
      max-width: $carousel-details-max-width;
      min-height: $carousel-details-min-height;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        margin-bottom: $card-heading-margin-bottom;
      }
      > p {
        opacity: 1;
        max-height: none;
      }
    }
  }
}



// Impact stats - Format
.homeFeatureSnippets td:first-of-type {
  max-width: 200px;
  min-width: 200px;
  max-height: 200px;
  min-height: 200px;
  border-radius: 200px;
  border: 20px solid $brand-secondary;
  display: flex;
  align-items: center;
  justify-content: center;
}


// Footer - Overall layout
.pageFooter {
  padding: 0;
}


// Footer newsletter background - Format
@media (min-width: map-get($breakpoints, md) + 1px) {
  .footerBox[class*="ewsletter"] {
    position: relative;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $brand-secondary;
      clip-path: polygon(calc(100% - 30%) 0%, 100% 0, 100% 100%, calc(100% - 25%) 100%);
      z-index: -1;
    }
  }
}


// Footer newsletter input - Format
.footerBox[class*="ewsletter"] input {
  min-height: $newsletter-button-height;
}



// Footer links - Layout
.footerBox:not([class*="ewsletter"]) ul.footerLinks {
  @media (min-width: map-get($breakpoints, md) + 1px) {
    display: flex;
    align-items: center;
    justify-content: center;
    li { margin: $spacer; }
  }
  a { font-weight: $font-weight-bold; }
}


// Footer - Social icons
.footerBox ul.socialIcons {
  justify-content: center;
  margin-top: #{$spacer * 2};
}

// Post - Headings
.postContent h2 { font-size: $font-size-h3; }
.postContent h3 { font-size: $font-size-h4; }
.postContent h4 { font-size: $font-size-h5; }
.postContent h5 { font-size: $font-size-h6; }

// Sidebar items - Format
.associated:not(.associatedBox) li:first-child { border-top: none; }
ul.associatedListing li:nth-child(3n){
  border-bottom: 0;
}
.associated:not(.associatedBox){
  background-color: $grey-lightest;
  padding: $spacer * 2;
  li{
    border-bottom: 1px solid #fff;
  }
}
.eventPostPage .postFeedWrapper{
  display:none;
}

// Appeal tabs - Format
ul.tabs {
  border-bottom: 0;
  .tab {
    background-color: transparent;
    transform: none;
    margin-right: $spacer;
    a {
      padding: #{$spacer * 0.25} 0;
      color: $body-colour;
      border-bottom: 3px solid $border-colour;
    }
    &.active {
      background-color: transparent;
      a {
        color: $brand-primary;
        border-bottom: 3px solid $brand-primary;
      }
    }
  }
}


// Appeal post totaliser - Format
.appealPostPage .contentBlock .postAside.appealWidgetsWrapper > * {
  background-color: transparent;
}


// Post content iframe - Layout
.postContent{
  .embed-container {
    margin: 20px 0;
    position: relative;
    padding-bottom: $carousel-yt-cover-padding-bottom;
    height: 0;
    overflow: hidden;
    width: 100%;
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


// All images - Format
.homeFeatures .homeFeature [class*="image-link"],
.homeFeed:not(.subsiteFeed) .feedItem [class*="image-link"],
.listedPost [class*="image-link"],
.accordianBody img, .listingIntro img, .postContent img {
  border-radius: $border-radius;
  overflow: hidden;
}


// Donation form button - Format
.paymentPage .donation-form-button {
 @include button(
    $btn-background-colour: $donate-colour,
    $btn-text-colour: text-contrast($donate-colour)
  ); 
}


// Disable Header donate on Appeals
.appealsPostBody, body.donate, body[class*=payment-] {
  .menuMain ul.topLevel > li > a[aria-label='Donate'] {
    display: none;
  }
}


// Listed items footer - Format
.listedPost.module-news footer p.publishDatesWrapper {
  display: block !important;
}


// Listed events - hide start date with category - and hide it on individual post
section[onclick^="window.location = '/Events/"].PostCategory_hide-event-dates footer .associatedStartDate,
.PostSystemCategory_hide-event-dates .eventWhenWrapper {
  display: none !important;
}

// Donation form totaliser - Position
.post .donationForm {
  display: flex;
  flex-direction: column;
}


// Donation form totaliser - Layout
.post .donationForm .appealTotaliser {
  order: -1;
  display: block;
  width: calc(100% - #{$site-bleed * 2});
  max-width: calc(#{$post-content-max-width} - #{$site-bleed * 2}) ;
  padding-left: $gap-width;
  padding-right: $gap-width;
  margin-left: auto;
  margin-right: auto;
  padding-top: calc(60px + #{$gap-width} + #{$spacer}); //  Totaliser width set just below
  .totaliserPanel {
    position: relative;
  }
  .totaliserPanel {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-top: 0;
  }
  .totaliserBG {
    margin: 0;
    position: absolute;
    width: 60px;
    height: calc(100vw - (100vw - #{$post-content-max-width}) - #{$site-bleed * 2} - #{$gap-width * 2});
    top: 0;
    left: 50%;
    transform: rotate(-90deg) translateX(20px) translateY(-50%);
    transform-origin: top left; 
    @media  (max-width: #{$post-content-max-width}) {
      height: calc(100vw - #{$site-bleed * 2} - #{$gap-width * 2});
    }
  }
}


// // // // // // // // // //
// Subsite - Annual Report //
// // // // // // // // // //



body.subsite[class*='annual-report'] {

//  temporarily hide admin bar as it covers sticky headers
  // section.adminBar {
  //   display: none;
  // }

  // section.adminBarEdit {
  //     display: none;
  // }

  // Hide menu
  .menuSub { display: none; }

  .homeFeeds {
    width: calc(100% - #{60px * 2});
    max-width: $container-max-width;
    display: block;
    @media (max-width: calc(#{$home-feeds-max-width} + (50px * 2))) {
      width: 100%;
    }
  }

  // Video carousel - Function
  .carouselSubsite .carouselSlideTitle {
    pointer-events: all;
  }
  
  // Header text - Overall layout
  .contentContainer, .post { position: relative; }
  .contentContainer .headerTextSubsite, .post .headerTextSubsite {
    padding: $carousel-details-padding;
    margin: 0;
    .subsiteTitle {
      font-size: $carousel-heading-font-size;
    }
    // Header text - Desktop layout
    @media (min-width: #{$carousel-breakpoint  + 1px}) {
      position: absolute;
      top: 0;
      left: $carousel-details-margin-x;
      height: 100%;
      max-height: 40.25vw;
      z-index: 1;
      width: $carousel-details-max-width;
      display: flex;
      align-items: center;
      justify-content: center;
      .subsiteTitle {
        color: $carousel-heading-colour;
      }
    }
    // Header text - Mobile layout
    @media (max-width: $carousel-breakpoint) {
      order: 3;
      background-color: $carousel-mobile-details-background-colour;
      .subsiteTitle {
        color: $carousel-mobile-heading-colour;
      }
    }
  }

  // Homepage feeds - Layout
  .homeFeedsWrapper {
    padding-top: $spacer;
    padding-bottom: $spacer;
    .homeFeeds {
      flex-direction: column;
    }
  }

  // Homepage feed - Layout
  .homeFeed {
    position: relative;
    width: 100%;
    background-color: $white;
    margin: $spacer 0;
  }
  
  // Homepage feed title - Format
  .homeFeed .feedsTitle {
    background-color: $white;
    position: sticky;
    top: 68px; //  Fallback
    margin-top: 0;
    padding-top: #{$spacer * 1.5};
    padding-bottom: $spacer;
    color: $brand-primary;
    font-size: 2.5rem;
    margin-bottom: 0;
    padding-left: #{$spacer * 1.5};
    padding-right: #{$spacer * 1.5};
    z-index: 2;

    @media  (max-width: $nav-breakpoint) {
      top: 72px;
      font-size: 2.25rem;
    }

    &:after { display: none; }
    &:before {
      content: '';
      display: block;
      width: 100%; // 5em;
      height: 5px;
      background-color: $brand-primary;
      margin-bottom: #{$spacer * 0.5};
    }
  }

  // Homepage feed intro - Format
  .homeFeed > p {
    font-size: 1.5rem;
    font-weight: bold;
    color: $brand-secondary;
    padding-left: #{$spacer * 1.5};
    padding-right: #{$spacer * 1.5};
  }

  // Feed - Cursor
  .feedItem li {
    font-size: $font-size-h5;
    color: text-contrast($brand-secondary);
  }

  // Image - Layout
  .feedItem:not(.cardNoDetails) [class*="image-link"] {
    text-align: center;
    cursor: auto;
    img  {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
    &:hover {
      text-decoration: none; // Specifically for when tables are wrapped in <a class="home-feed-image-link">
    }
  }
  

  // Details - Layout
  .feedItem:not(.cardNoDetails) [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    position: relative;
    cursor: auto;
    color: text-contrast($brand-primary);
    > p, > ul, h1, h2, h3, h4, h5 {
      margin: 0 #{$spacer * 1.5} $spacer!important;
    }
    a{
      color: white;
      text-decoration: underline;
    }
    // Buttons - Format
    a.cta-button {
      @include button (
        $btn-background-colour: $brand-primary,
        $btn-text-colour: text-contrast($brand-primary),
        $btn-border-radius: 0,
      );
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0;
      pointer-events: all;
      background-color: $brand-secondary;
      &:hover{
        background-color: darken($brand-secondary, 5%)
      }
    }
  }

  .feedItem:not(.cardNoDetails):not(.cardTable.cardNoText):not(.cardOneImage.cardTable) [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    padding: #{$spacer * 2} 0!important;
  }

  //  Social icons format
  .feedItem:not(.cardNoDetails) [class*="DetailsWrapper"]:not(.publishDetailsWrapper) ul.socialIcons {
    margin-top: $spacer;
    a {
      position: relative;
      font-size: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 0;
      background-color: transparent;
      text-decoration: none;
      &:hover{
        text-decoration: none;
      }
      &:before {
        font-size: 1.75rem;
        color: $white;
        text-decoration: none;
      }
    }
  }

  // Bespoke feed title backgrouds
  .homeFeed.homeFeedBox1 {
    .feedsTitle {
      background-color: $brand-primary;
      color: text-contrast($brand-primary);
      &:before { background-color: text-contrast($brand-primary); }
    }
  }

  // Slick slider - Layout
  @media (min-width: $container-max-width) {
    .slick-slider {
      max-width: calc(#{$container-max-width} - 200px);
      margin: auto;
    }
  }
  .slick-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    .slick-track {
      display: flex;
    }
  }

  // Slick arrows - Overall layout
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border-radius: 0;
    font-size: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    outline: none !important;

    // Overall format
    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $icon-font-family;
      color: $brand-secondary;
      width: 100px;
      height: 200px;
      font-size: 200px;
    }
    
    &:hover {
      background-color: transparent;
      &:before { color: $brand-primary; }
    }

    // Specific format + layout
    &.slick-arrow {
      color: $brand-primary;
    }
    &.slick-prev {
      left: -100px;
      &:before { 
        content: '\f104'; 
      }
    }
    &.slick-next {
      right: -100px;
      &:before { 
        content: '\f105';
      }
    }

    // Mobile layout
    @media (max-width: $container-max-width) {
      &:before {
        background-color: white;
        width: 50px;
        height: 50px;
        font-size: 2.5rem;
      }
      &.slick-arrow {
        transform: none;
        top: unset;
        bottom: 0;
      }
      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        right: 0;
      }
    }
  }


  // Feed entry annimation
  .feedItem.in-view:not(.already-in-view) {
    animation-duration: 0.25s;
  }


  // Bespoke feed content table
  .homeFeed .feedList .feedItem:not(.cardNoDetails) {
    table {
      border: none;
      margin: 0;
      table-layout: fixed;
      text-decoration: none;
      tr {  
        td {
          text-align: center;
          padding: 0;
          height: 300px!important;
          vertical-align: middle;
          color: $brand-primary;
          font-size: $font-size-large;
          font-weight: bold;
          border: 0;
          h3 {
            font-size: 3em;
            color: $brand-primary;
            margin: 20px 20px 0!important;
          }
          p {
            margin: 0 20px 20px;
          }
          img{
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      tr:nth-child(1) td:nth-child(1),
      tr:nth-child(2) td:nth-child(3),
      tr:nth-child(3) td:nth-child(4) {
        background: $_yellow-light;
        h3, p {
          color: $brand-primary;
        }
      }
      tr:nth-child(1) td:nth-child(2),
      tr:nth-child(2) td:nth-child(4),
      tr:nth-child(3) td:nth-child(3) {
        background: $brand-primary;    
        color: white;
        h3, p {
          color: white;
        }  
      }
      tr:nth-child(1) td:nth-child(3),
      tr:nth-child(2) td:nth-child(1),
      tr:nth-child(3) td:nth-child(2) {
        background: $brand-secondary;
        color: white;
        h3, p {
          color: white;
        }  
      }
      tr:nth-child(1) td:nth-child(4),
      tr:nth-child(2) td:nth-child(2),
      tr:nth-child(3) td:nth-child(1) {
        background: $grey-lighter-better-contrast-annual-report;
        h3, p {
          color: $brand-primary;
        }
      }  
      tr td {
        @media (max-width: $container-max-width) {
          h3 {
            font-size: 2.5em;
            margin: 20px 10px 0!important;
          }
          p {
            font-size: .9em;
          }
        }
        @media (max-width: map-get($breakpoints, md)) {
          h3 {
            font-size: 2em;
            margin: 20px 10px 0!important;
          }
          p {
            font-size: .8em;
            margin: 0 10px 20px;
          }
        }
        @media (max-width: map-get($breakpoints, sm)) {
          h3 {
            font-size: 1.5em;
            margin: 20px 5px 0!important;
          }
          p {
            font-size: .75em;
          }
        }
      }
      
    }
    &.cardTable.cardOneImage, 
    &.cardTable.cardText{
      table{
        tr td {
          @media (min-width: $container-max-width) {
            h3 {
              font-size: 2.5em;
              margin: 20px 10px 0!important;
            }
            p {
              font-size: .9em;
            }
          }
        }
      }
    }
        
  }

  // Bespoke card layout
  .cardNoDetails {
    display: block !important;
    min-width: 100%;
    .feedItemDetailsWrapper {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      img {
        flex: 1;
        min-width: 290px;
      }
    }
  }

  // Blockquote

  blockquote {
    font-family: cursive;
    padding: #{$spacer * 2} #{$spacer * 3};
    margin: 0;
    background: transparent;
  }
  
}

// Custom newsletter https://raisingit.atlassian.net/browse/CR-166
.ml-form-embedHeader,
.ml-form-embedContent {
  display: none;
}

.ml-form-embedContainer {
  max-width: $container-max-width;
  margin: auto;
  padding: 0 $spacer;
}

.ml-form-formContent {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ml-form-fieldRow {
  margin: $spacer*0.5;
  flex: 1;
  min-width: 200px;
  * {
    width: 100%;
  }
  input::placeholder {
    color: $grey-light;
    font-size: 1rem !important;
  }
}

.ml-form-checkboxRow .checkbox {
  display: flex;
  .label-description {
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: $spacer;
    p {
      margin-bottom: 0;
    }
  }
}

.ml-form-embedPermissions {
  margin-top: $spacer;
}