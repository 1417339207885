// Home features - Overall layout
.homeFeatures {
  @media (min-width: #{$card-text-overlay-breakpoint + 1px})  {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 13
    );
  }
  @media (max-width: $card-text-overlay-breakpoint)  {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 4
    );
  }
}

// Home features - Card layout
.homeFeature:not(.homefeaturecategory-homeboximpactstats):not(.homefeaturecategory-homeboxquickgiving) {
  @media (min-width: #{$card-text-overlay-breakpoint + 1px})  {
    @include card (
      $card-details-padding: $spacer * 1.5,
      $card-heading-font-size: $font-size-h4,
      $card-details-background-colour: transparent,
      $card-hover-details-background-colour: transparent,
      $card-details-background-gradient: linear-gradient(to top, rgba($black, 0.7) 0, rgba($black, 0) 100%),
      $card-hover-details-background-gradient: linear-gradient(to top, rgba($black, 0.7) 0, rgba($black, 0) 100%),
      $card-heading-colour: $white,
      $card-hover-heading-colour: $white,
      $card-summary-colour: $white,
      $card-hover-summary-colour: $white,
    );
    @include card-text-overlay;

    &.homefeaturecategory-homeboxfullwidth {
      @include card (
        $card-gap-width: 0,
        $card-border-radius: 0,
        $card-image-border-radius: 0,
        $card-heading-font-size: $carousel-heading-font-size,
        $card-details-padding: 0 $carousel-details-padding 0 #{$carousel-details-padding + $carousel-details-margin-x},
        $card-details-background-colour: transparent,
        $card-hover-details-background-colour: transparent,
        $card-details-background-gradient: transparent,
        $card-hover-details-background-gradient: transparent,
        $card-heading-colour: $white,
        $card-hover-heading-colour: $white,
        $card-summary-colour: $white,
        $card-hover-summary-colour: $white,
      );
      @include card-text-overlay (
        $card-text-overlay-show-summary-on-hover: false,
        // $card-text-overlay-summary-always-on: true,
        $card-text-overlay-details-max-width: $carousel-details-max-width,
        $card-text-overlay-details-position-y: center,
        $card-text-overlay-details-position-x: right
      );
    }
  }

  // Resposive
  @media (max-width: $card-text-overlay-breakpoint) {
    @include card (
      $card-details-padding: $spacer 0,
    );
    @include card-basic;

    &.homefeaturecategory-homeboxfullwidth {
      @include card (
        $card-gap-width: 0,
        $card-border-radius: 0,
        $card-image-border-radius: 0,
        $card-details-padding: $spacer,
        $card-details-background-colour: $brand-primary,
        $card-hover-details-background-colour: $brand-primary,
        $card-heading-font-size: $carousel-heading-font-size,
        $card-heading-colour: $white,
        $card-hover-heading-colour: $white,
        $card-summary-colour: $white,
        $card-hover-summary-colour: $white,
      );
      @include card-basic;
    }
  }
}


// Home feeds - Overall layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 2
);

// Home feed - Overall layout
body:not(.subsite) .homeFeed .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 2,
    $prefab: 1
  );
}


// Home feed - Card layout
body:not(.subsite) .feedItem {
  @include card (
    $card-details-padding: $spacer 0,
    $card-details-background-colour: transparent,
    $card-hover-details-background-colour: transparent
  );
  @include card-basic;
}


// Listed posts - Card style
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem):not(.PostCategory_unclickable) {
  @include card (
    $card-details-padding: $spacer 0,
    $card-details-background-colour: transparent,
    $card-hover-details-background-colour: transparent,
    $card-footer-on: true
  );
  @include card-basic;
}


// Listed posts - Team card style
.PostCategory_unclickable {
  @include card (
    $card-details-padding: $spacer 0,
    $card-details-background-colour: transparent,
    $card-hover-details-background-colour: transparent,
    $card-footer-on: true,
    $card-hover-image-scale: 1,
    $card-text-align: center
  );
  @include card-basic;
}



// Listed products - Card sttyle
.listedProduct {
  @include card;
  @include card-basic;
}



// Subsite - Annual report
body.subsite[class*='annual-report'] {

  // Home feeds - Overall layout
  .homeFeed .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 12
    );
    // Home feed - Card layout
    .feedItem:not(.cardNoDetails) {
      @include card (
        $card-gap-width: 0,
        $card-border-radius: 0,
        $card-image-border-radius: 0,
        $card-heading-font-size: $font-size-h3,
        $card-details-padding: 0,
        $card-details-background-colour: $brand-primary,
        $card-hover-details-background-colour: $brand-primary,
        $card-heading-colour: text-contrast($brand-primary),
        $card-hover-heading-colour: text-contrast($brand-primary),
        $card-summary-colour: text-contrast($brand-primary),
        $card-hover-summary-colour: text-contrast($brand-primary),
        $card-summary-font-size: $font-size-large
      );
      @include card-side-by-side;
    }
  }

  // Home feed - Specific layout
  .homeFeed[class*='ission'],
  .homeFeed[class*='hairperson'],
  .homeFeed[class*="ustainable"],
  .subsiteFeedBox15
  {
    .feedList .feedItem:not(.cardNoDetails) {
      @include card (
        $card-gap-width: 0,
        $card-border-radius: 0,
        $card-image-border-radius: 0,
        $card-heading-font-size: $font-size-h3,
        $card-details-padding: 0,
        $card-details-background-colour: $white,
        $card-hover-details-background-colour: $white,
        $card-heading-colour: text-contrast($white),
        $card-hover-heading-colour: text-contrast($white),
        $card-summary-colour: text-contrast($white),
        $card-hover-summary-colour: text-contrast($white),
        $card-summary-font-size: $font-size-large
      );
      @include card-side-by-side;
      ul li{
        color: text-contrast($white);
      }
    }
  }
  
  // Home feed - Specific layout - yellow bg
  .homeFeed.OurGovernanceandTeamFeed.subsiteFeedBox4, // can't target just with category class as there is another feed with same one
  .homeFeed.PublicOutReachandActivationFeed.subsiteFeedBox12, // can't target just with category class as there is another feed with same one
  .subsiteFeedBox16,
  .subsiteFeedBox17 {
    .feedList .feedItem:not(.cardNoDetails) { 
      @include card (
        $card-gap-width: 0,
        $card-border-radius: 0,
        $card-image-border-radius: 0,
        $card-heading-font-size: $font-size-h3,
        $card-details-padding: 0,
        $card-details-background-colour: $_yellow-light,
        $card-hover-details-background-colour: $_yellow-light,
        $card-heading-colour: text-contrast($_yellow-light),
        $card-hover-heading-colour: text-contrast($_yellow-light),
        $card-summary-colour: text-contrast($_yellow-light),
        $card-hover-summary-colour: text-contrast($_yellow-light),
        $card-summary-font-size: $font-size-h5
      );
      @include card-side-by-side;
    }
  }

  // Home feed - Specific layout - light green bg
  .homeFeed[class*='estimonial'] {
    .feedList .feedItem:not(.cardNoDetails) { 
      @include card (
        $card-gap-width: 0,
        $card-border-radius: 0,
        $card-image-border-radius: 0,
        $card-heading-font-size: $font-size-h3,
        $card-details-padding: #{$spacer * 2} #{$spacer * 1.5},
        $card-details-background-colour: $brand-secondary,
        $card-hover-details-background-colour: $brand-secondary,
        $card-heading-colour: text-contrast($brand-secondary),
        $card-hover-heading-colour: text-contrast($brand-secondary),
        $card-summary-colour: text-contrast($brand-secondary),
        $card-hover-summary-colour: text-contrast($brand-secondary),
        $card-summary-font-size: $font-size-h5
      );
      @include card-side-by-side;
    }
  }

}